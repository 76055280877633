<template>
  <div class="px-0 container-fluid h-100"
    style="text-align: left">
    <div class="container"
      style="padding-top: 30px">
      <BreadCrumbComponent :pageTitles="['Intro Banners']" />
    </div>
    <section>
      <article class="mt-4">
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;">
                <strong>Intro Banners</strong><br />
              </div>
            </div>
          </div>

        </div>
      </article>
    </section>

    <section v-loading="isBusy">
      <!--- SHOW THIS ARTICLE WHEN FETCHING TEAMS FROM THE DATABASE HAS FAILED --->
      <article v-if="isLoadingError"
        class="d-flex align-items-center justify-content-center"
        style="height: 180px">
        <div class="text-center">
          <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
          <div class="pt-2"
            style="font-weight: 400; font-size: 0.9em">
            Failed to fetch Teams now. Please try again
          </div>
          <button type="button"
            class="mx-0 mt-3 btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="getIntroBanners"
            style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        ">
            <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
          </button>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN STILL FETCHING TEAMS FROM THE SERVER --->
      <article v-show="isFetching && !isLoadingError"
        class="">
        <div class="container">
          <div class="our_teams_grid">
            <div v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
              style="height: 300px; border-radius: 5px"></div>
          </div>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN TEAMS ARE FETCHED FROM THE SERVER --->
      <article v-if="!isFetching && !isLoadingError">
        <div class="container">
          <div class="our_teams_grid">

            <div class="our_team_container"
              v-for="(banner, index) in intro_banner_section_items"
              :key="index">
              <div class="our_team_container_content">
                <div>
                  <div class="publish_content">
                    <img :src="banner.image_path_url"
                      style="width: 100%; height: 100%; object-fit: cover;">
                  </div>

                  <div class="publishing_status">
                    <el-button :type="banner.is_published == true ? 'success' : 'danger'"
                      @click="changePublishStatus(banner.is_published, banner.website_intro_section_banner_id)"
                      size="mini">
                      {{ banner.is_published == true ? 'Published' : 'Not Published' }}
                    </el-button>
                  </div>
                </div>
                <div class="p-4">
                  <div>
                    <h2 style="font-size: 1.5em; font-weight:600; color: white;">
                      {{ banner.title }}
                    </h2>
                  </div>
                  <div>
                    <p style="font-size: 0.9em; font-weight:300; color: white;">
                      {{ banner.description }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="our_team_buttons">
                <el-tooltip content="Move up"
                  placement="top">
                  <el-button type="info"
                    icon="el-icon-top"
                    circle
                    size="mini"
                    :disabled="banner.orderIndex == 0"
                    @click="moveBannerUp(banner.website_intro_section_banner_id, banner.orderIndex)"></el-button>
                </el-tooltip>
                <el-tooltip content="Move Down"
                  placement="top">
                  <el-button type="info"
                  icon="el-icon-bottom"
                  size="mini"
                  circle
                  :disabled="banner.orderIndex == (intro_banner_section_items.length - 1)"
                  @click="moveBannerDown(banner.website_intro_section_banner_id, banner.orderIndex)"></el-button>
                </el-tooltip>
                <el-button type="warning"
                  icon="el-icon-edit"
                  circle
                  @click="editIntroBanner(banner.website_intro_section_banner_id)"></el-button>
                <el-button type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteIntroBanner(banner.website_intro_section_banner_id)"></el-button>
              </div>
            </div>
            <AddIntroBannerDialog @on-refresh="getIntroBanners" />
          </div>
        </div>
      </article>
    </section>

    <EditIntroBannerDialog :isEditIntroBannerDialogVisible="isEditIntroBannerDialogVisible"
      :introBannerId="website_intro_section_banner_id"
      @on-refresh="getIntroBanners"
      @on-close-dialog="closeEditIntroBannerDialog" />
  </div>
</template>

<script>
import AddIntroBannerDialog from "../components/dialogs/add-intro-banner-dialog.vue";
import EditIntroBannerDialog from "../components/dialogs/edit-intro-banner-dialog.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";

export default {
  components: {
    AddIntroBannerDialog,
    EditIntroBannerDialog,
    BreadCrumbComponent,
  },

  data() {
    return {
      isFetching: true,
      isLoadingError: false,
      isBusy: false,
      intro_banner_section_items: [],
      website_intro_section_banner_id: "",
      isEditIntroBannerDialogVisible: false,
      loading: false,
      loadingError: false,
    };
  },

  mounted() {
    this.getIntroBanners();
  },

  methods: {
    editIntroBanner(website_intro_section_banner_id) {
      this.isEditIntroBannerDialogVisible = true;
      this.website_intro_section_banner_id = website_intro_section_banner_id;
    },

    closeEditIntroBannerDialog() {
      this.isEditIntroBannerDialogVisible = false;
    },

    async getIntroBanners() {
      try {
        this.isFetching = true;
        this.isLoadingError = false;
        let request = await this.$http.get(
          `website/intro-section-banner/items`
        );
        if (
          request.data.success &&
          request.data.message ==
          "INTRO BANNER SECTION ITEMS FETCHED SUCCESSFULLY"
        ) {
          this.intro_banner_section_items =
            request.data.intro_banner_section_items.map((item, index) => {
              return {
                website_intro_section_banner_id: item.website_intro_section_banner_id,
                title: item.title,
                description: item.description,
                image_path_url: item.image_path_url,
                on_click_url: item.on_click,
                is_published: item.is_published,
                orderIndex: index
              }
            });
          this.isFetching = false;
          this.isLoadingError = false;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isFetching = false;
        this.isLoadingError = true;
        return this.showFailedMessage(
          "Operation Failed",
          "Unable to load Intro banners"
        );
      }
    },

    async changePublishStatus(is_published, website_intro_section_banner_id) {
      await this.$confirm(
        `Are you sure you want to change the publish status?`,
        "Confirm Changes",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.isBusy = true;
        let request = await this.$http.patch(
          `website/intro-section-banner/items/${website_intro_section_banner_id}/toggle-publish`
        );
        if (
          request.data.success &&
          request.data.message ==
          "INTRO BANNER ITEM PUBLISHED STATUS TOGGLED SUCCESSFULLY"
        ) {
          this.getIntroBanners();
          return this.showSuccessMessage(
            "Updated successfully",
            "The Published status of the intro banner item was changed successfully."
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "INTRO BANNER ITEM NOT FOUND") {
            this.getIntroBanners();
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the selected Intro banner item"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        return this.showFailedMessage(
          "Operation Failed",
          "Unable To Change Intro Banner Publish status"
        );
      } finally {
        this.isBusy = false;
      }
    },

    async deleteIntroBanner(website_intro_section_banner_id) {
      await this.$confirm(
        "This will permanently delete this Intro Banner. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.isBusy = true;
        let request = await this.$http.delete(
          `website/intro-section-banner/items/${website_intro_section_banner_id}`
        );
        if (
          request.data.success &&
          request.data.message ==
          "INTRO SECTION BANNER ITEM DELETED SUCCESSFULLY"
        ) {
          this.getIntroBanners();
          this.showSuccessMessage(
            "Deleted Successfully",
            "The selected Intro Banner item was successfully deleted."
          );
          this.isBusy = false;
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "INTRO BANNER ITEM NOT FOUND") {
            this.getIntroBanners();
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the selected Banner"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        return this.showFailedMessage(
          "Operation Failed",
          "Unable To Delete Banner"
        );
      } finally {
        this.isBusy = false;
      }
    },

    moveBannerUp(bannerId, bannerOrderIndex) {
      // get previous banner as we want to swap with it
      const affectedBanner = this.intro_banner_section_items.filter(item => item.website_intro_section_banner_id == bannerId)[0];
      const currentIndex = affectedBanner.orderIndex;

      const prevBanner = this.intro_banner_section_items.filter(item => item.orderIndex == (bannerOrderIndex - 1))[0]

      // swap with previous banner
      affectedBanner.orderIndex = prevBanner.orderIndex
      prevBanner.orderIndex = currentIndex

      this.saveBannerOrder()
    },

    moveBannerDown(bannerId, bannerOrderIndex) {
      // get previous banner as we want to swap with it
      const affectedBanner = this.intro_banner_section_items.filter(item => item.website_intro_section_banner_id == bannerId)[0];
      const currentIndex = affectedBanner.orderIndex;

      const prevBanner = this.intro_banner_section_items.filter(item => item.orderIndex == (bannerOrderIndex + 1))[0]

      // swap with previous banner
      affectedBanner.orderIndex = prevBanner.orderIndex
      prevBanner.orderIndex = currentIndex

      this.saveBannerOrder()
    },

    async saveBannerOrder() {
      let request = await this.httpRequest({
        method: "PATCH",
        url: "website/save-order-indexes",
        loadingPropertyName: "isBusy",
        errorLoadingPropertyName: "isLoadingError",
        showSuccessMessage: true,
        body: {
          itemType: "WEBSITE_INTRO_SECTION_BANNERS",
          items: this.intro_banner_section_items.map(item => {
            return {
              id: item.website_intro_section_banner_id,
              index: item.orderIndex
            }
          })
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Items order saved successfully"
      ) {
        this.getIntroBanners();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.our_teams_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.our_teams_grid>div {
  background-color: white;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.our_teams_grid>div:hover {
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.our_teams_grid>.our_team_container {
  position: relative;
}

.our_teams_grid>.our_team_container .our_team_buttons {
  display: none;
}

.our_teams_grid>.our_team_container:hover .our_team_buttons {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}

.our_team_buttons .el-icon-edit {
  font-size: 1.5em;
  margin-right: 10px;
  color: orange;
  cursor: pointer;
}

.our_team_buttons .el-icon-delete {
  font-size: 1.5em;
  color: red;
  cursor: pointer;
}

.our_teams_grid>.our_team_container .our_team_container_content {
  background-color: #5f3889;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
}

.our_teams_grid>.our_team_container .our_team_container_content>div {
  width: 100%;
  border-radius: 5px;
}

.our_teams_grid>.our_team_container .our_team_container_content>div:first-child {
  border-radius: 5px 0px 0px 5px;
  height: 300px;
  background-color: #2a70b5a0;
  color: white;
  position: relative;
}

.our_teams_grid>.our_team_container .our_team_container_content .publish_content {
  height: 100%;
  width: 100%;
  font-size: 3em;
}

.our_teams_grid>.our_team_container .our_team_container_content .publish_content img {
  border-radius: 5px 0px 0px 5px;
}

.our_teams_grid>.our_team_container .our_team_container_content>div:first-child {
  width: 60%;
  border-radius: 5px 0px 0px 5px;
}

.our_teams_grid>.our_team_container .our_team_container_content>div:nth-child(2) {
  width: 40%;
  /* text-align: center; */
  background-color: #5f3889;
  color: black;
  padding-top: 20px 20px;
  border-radius: 0px 0px 5px 5px;
  /* height: 100px; */
}

.our_teams_grid>.our_team_container .our_team_container_content .publishing_status {
  position: absolute;
  bottom: 0px;
  right: 0px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .our_teams_grid>.our_team_container .our_team_container_content {
    display: block;
    width: 100%;
  }

  .our_teams_grid>.our_team_container .our_team_container_content>div:first-child {
    width: 100%;
  }

  .our_teams_grid>.our_team_container .our_team_container_content>div:nth-child(2) {
    width: 100%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .our_teams_grid>.our_team_container .our_team_container_content {
    display: block;
    width: 100%;
  }

  .our_teams_grid>.our_team_container .our_team_container_content>div:first-child {
    width: 100%;
  }

  .our_teams_grid>.our_team_container .our_team_container_content>div:nth-child(2) {
    width: 100%;
  }
}
</style>

<style>
.publishing_status .el-button {
  border-radius: 5px 0px 0px 5px;
}
</style>