<template>
  <div>
    <div
      class="addDialogTrigger z-depth-0"
      @click="openIntroBannerModal"
    >
      <div
        class="w-100 d-flex align-items-center justify-content-center"
        style="height: 300px;"
      >
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <section>
      <div class="container">
        <div class="team_dialogs">
          <el-dialog
            title="Add Banner"
            :visible.sync="addIntroBannerModal"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="submitting || isUploadingFile">
              <el-form
                :model="introBannerForm"
                label-position="top"
                :rules="introBannerRules"
                ref="introBannerForm"
                class="demo-introBannerForm"
                onSubmit="return false;"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="title">
                      <label for="title">Title</label>
                      <el-input
                        id="title"
                        v-model="introBannerForm.title"
                        @keyup.enter.native="addIntroBanner('introBannerForm')"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="on_click_url">
                      <label for="on_click_url">On Click URL <strong style="color: grey;"><i>(Optional)</i></strong></label>
                      <el-input
                        id="on_click_url"
                        v-model="introBannerForm.on_click_url"
                        @keyup.enter.native="addIntroBanner('introBannerForm')"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="description">
                      <label for="description">Description</label>
                      <el-input
                        id="description"
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="Please input"
                        v-model="introBannerForm.description"
                        maxlength="400"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-left">
                    <div
                      class="mb-3"
                      style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
                    >
                      Choose File
                    </div>
                    <el-upload
                      class="upload-demo"
                      drag
                      :action="''"
                      :file-list="fileLists"
                      :on-remove="handleRemove"
                      :on-change="handlePreview"
                      :auto-upload="false"
                      :multiple="false"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </el-upload>
                  </div>
                </div>

                <el-form-item class="text-center mt-5">
                  <el-button
                    type="primary"
                    @click="addIntroBanner('introBannerForm')"
                  >Add Banner</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small></small>
                </div>
              </div>

            </div>
          </el-dialog>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      addIntroBannerModal: false,
      submitting: false,
      isUploadingFile: false,

      selectedFile: null,
      fileLists: [],
      introBannerForm: {
        title: "",
        on_click_url: "",
        description: "",
      },
      introBannerRules: {
        title: [
          {
            required: true,
            message: "Title is required",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Description is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    handleClose(done) {
      this.clearInputs();
      done();
    },
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file;
    },
    handleRemove() {
      this.selectedFile = null;
    },

    openIntroBannerModal() {
      this.addIntroBannerModal = true;
    },
    clearInputs() {
      this.introBannerForm.title = "";
      this.introBannerForm.on_click_url = "";
      this.introBannerForm.description = "";
    },

    async addIntroBanner(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.selectedFile);
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.isUploadingFile = false;
            return this.showWarningMessage(
              "Empty",
              "Banner Intro Image is empty"
            );
          }
          try {
            this.isUploadingFile = true;
            let request = await this.$http.post(
              `website/request-for-file-upload-url`,
              {
                file_name: this.selectedFile.raw.name,
                file_size: this.selectedFile.raw.size,
                reason: "INTRO_SECTION_BANNER",
              }
            );
            if (
              request.data.success &&
              request.data.message == "UPLOAD URL GENERATED SUCCESSFULLY"
            ) {
              this.aws_upload_url = request.data.upload_url;
              this.uploadBannerToAWS(
                this.aws_upload_url,
                this.selectedFile.raw
              );
            } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
              return this.showWarningMessage(
                "Unsupported file",
                "The file you are trying to upload is not supported"
              );
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.showWarningMessage(
                "Connection failed",
                "A network error occurred please try again"
              );
            }
            this.isUploadingFile = false;
            return this.showFailedMessage(
              "Upload Failed",
              "Unable to Upload Banner Intro Photo now, Please try again"
            );
          }
        } else {
          return false;
        }
      });
    },
    // ___________________________________________________ uploading files
    async uploadBannerToAWS(aws_upload_url, selected_file) {
      this.isUploadingFile = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.isUploadingFile = true;
        console.log(selected_file);
        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (request.status == 204) {
          console.log(request.headers.location);
          this.onAWSBannerUploaded(request.headers.location);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Banner Intro Image  Now, please try again"
        );
      }
    },

    async onAWSBannerUploaded(profile_photo_path_url) {
      this.isUploadingFile = true;
      try {
        this.isUploadingFile = true;
        let request = await this.$http.post(
          `website/intro-section-banner/items`,
          {
            title: this.introBannerForm.title,
            on_click_url: this.introBannerForm.on_click_url,
            description: this.introBannerForm.description,
            image_path_url: decodeURIComponent(profile_photo_path_url),
          }
        );
        if (
          request.data.success &&
          request.data.message == "INTRO SECTION BANNER ITEM ADDED SUCCESSFULLY"
        ) {
          this.$emit("on-refresh");
          this.addIntroBannerModal = false;
          return this.showSuccessMessage(
            "Banner Added",
            "The intro Item banner was successfully added."
          );
        } else {
          throw "error occurred";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "ON CLICK URL IS INVALID") {
            return this.showWarningMessage(
              "Invalid Link",
              "The Banner onclick link is not a valid URL Link. Please review it and try again."
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        return this.showFailedMessage(
          "Operation Failed",
          "Unable To Add Intro Banner"
        );
      } finally {
        this.isUploadingFile = false;
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.team_dialogs .el-dialog {
  width: 40%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .team_dialogs .el-dialog {
    width: 50%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .team_dialogs .el-dialog {
    width: 70%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
</style>